import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import getLocation from '../components/get-location'

const Location = (props) => {
  const { t, i18n } = useTranslation()
  const tt = (key) => t(`location.${key}`)
  const [ location, setLocation ] = useState([])
  const { latitude, longitude } = getLocation(i18n.language)
  if (!location.length) {
    setLocation([latitude, longitude])
  }

  const storeLocation = (location) => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(`issTimes_${latitude}_${longitude}`)
      window.localStorage.setItem('location', JSON.stringify({ latitude: location[0], longitude: location[1] }))
    }
    setLocation(location)
  }
  const pointMode = {
    banner: false,
    control: {
      values: [location],
      onClick: point => storeLocation(point),
      onRemove: () => setLocation([])
    }
  }
  const requestPosition = () => {
    navigator.geolocation.getCurrentPosition(({ coords }) =>
      storeLocation([coords.latitude, coords.longitude])
    )
  }
  let LocationPicker
  if (typeof window !== 'undefined') {
    LocationPicker = require('react-leaflet-location-picker').default
  }
  return <>
    <p>{tt('pick-location')}</p>
    <div style={{ display: 'flex', 'justifyContent': 'flex-end' }} >
      {typeof window !== 'undefined' && window.history.length > 2 && <button
        onClick={() => {
          window.history.back()
        }}
        className='button -primary'
        tabIndex={0}
      >
        {tt('Back')}
      </button>}
      <button
        onClick={requestPosition}
        className='button -primary'
        tabIndex={0}
      >
        { tt('determine-location') }
      </button>
    </div>
    <br /><br />
    { !!LocationPicker && <LocationPicker
      showInputs={false}
      showControls={false}
      pointMode={pointMode}
      mapStyle={{ height: '500px', width: 'auto' }}
    />}
  </>
}

export default Location
